import { useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';

import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import PublicRoutes from './routes/PublicRoutes';
import { AuthContext } from './contexts/AuthProvider';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Login from './views/authentication/Login';

const App = () => {
  const { currentUser, loggingIn } = useContext(AuthContext);

  const appRoutes = useRoutes(Router);
  const publicRoutes = useRoutes(PublicRoutes);

  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {loggingIn ?
          <div className='loader-view'>
            <div className='loader'>
              <div className='loader-circle' />
            </div>
          </div>
          : (
            !currentUser
              ?
              <>
                {publicRoutes}
                <Login />
              </>
              :
              appRoutes
          )
        }
      </RTL>
    </ThemeProvider>
  );
};

export default App;
